import { FC, ReactNode } from "react";
import { icons } from "../../icons/IconSet";
import { IMessageType } from "../../model/IMessage";

const messageIcon: Record<IMessageType, { bgColor: string, icon: ReactNode }> = {
  System:   { bgColor: 'bg-gray-200', icon: icons('triangle-alert') },
  Note:     { bgColor: 'bg-[#C8E9FF]', icon: icons('note') },
  Public:   { bgColor: 'bg-gray-200', icon: icons('message-square-text') },
  Private:  { bgColor: 'bg-[#FFE2AB]', icon: icons('message-square-dashed') },
}

const TicketMessageIcon: FC<{ 
  msgType: IMessageType,
  isFromEmail?: boolean,
}> = ({ 
  msgType = 'Public',
  isFromEmail,
}) => (
  <div className={`
    ${messageIcon[msgType]?.bgColor || 'bg-gray-200'}
     relative rounded-[50%] w-[50px] h-[50px] flex items-center justify-center text-[#666666]
  `}>
    {messageIcon[msgType]?.icon || icons('message-square-text')}
    {!!isFromEmail && (
      <div className="absolute bottom-0 right-[-.5em] bg-blue-500 p-[.3em] pb-[.4em] text-sm font-semibold rounded-full leading-3 text-white">
        @
      </div>
    )}
  </div>
);

export default TicketMessageIcon;
